<template>
  <!-- 选择产线人员 -->
  <div class="selectPersonnel_table">
    <van-popup class="selectPersonnel_popup"
               v-model="showPersonnelPop"
               closeable
               close-icon="arrow-left"
               close-icon-position="top-left"
               position="right"
               teleport="body"
               overlay-class="selectPersonnel_popup_overlay">
      <van-sticky class="fixed_div">
        <div class="tree_title">
          <span>{{ `请选择${popTitle}` }}</span>
        </div>
        <div class="tree_search">
          <van-field v-model="lineName"
                     class="line_cell"
                     label="产线"
                     right-icon="arrow"
                     readonly
                     placeholder="请选择"
                     @click="showLinePicker = true" />
          <div class="search_box">
            <van-field v-model="searchPopVal"
                       clearable
                       :placeholder="$t('module.PleaseEnterKeyWords')"
                       @input="changeSearch" />
            <div class="icon_box">
              <van-icon name="search"
                        @click="onSearchPerson" />
            </div>
          </div>
        </div>
        <van-divider :style="{
          color: '#E8E8E8',
          borderColor: '#E8E8E8',
          margin: '5px 0 0 0',
        }" />
      </van-sticky>
      <div class="table_box"
           style="overflow: auto;">
        <table ref="table"
               class="table">
          <!-- 表头 -->
          <tr class="top">
            <th v-for="(item_th, index_th) in autoThList"
                :key="index_th">
              <div class="title"
                   :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                {{ item_th.chnname }}
              </div>
            </th>
          </tr>
          <!--展示列表数据 -->
          <tr class="cont"
              v-for="(item_tr, index_tr) in autoTableData"
              :key="index_tr"
              @click.stop="changeAuditDataByTd(item_tr)">
            <td v-for="(item_td, index_td) in autoThList"
                :key="index_td">
              <div class="comm_box">
                <div class="boolean_box"
                     v-if="item_td.datatype == 'checkbox'">
                  <van-checkbox v-model="item_tr[item_td.fieldname]"
                                :disabled="item_td.fieldname == 'isactive'"
                                :shape="single || item_td.fieldname == 'isactive'?'round':'square'"></van-checkbox>
                </div>
                <div class="input_box"
                     v-else-if="item_td.datatype == 'num'">
                  <van-field v-model="item_tr[item_td.fieldname]"
                             type="number"
                             :placeholder="item_td.chnname" />
                </div>
                <div v-else>{{ item_tr[item_td.fieldname] }}</div>
              </div>
            </td>
          </tr>
          <tr class="nodata"
              v-if="autoTableData.length == 0">
            <td align="center"
                :colspan="autoThList.length+1">暂无数据</td>
          </tr>
        </table>
      </div>
      <div class="paging_box">
        <van-pagination v-model="currentPage"
                        :total-items="totalItems"
                        :items-per-page="pageSize"
                        :show-page-size="3"
                        force-ellipses
                        @change="changePage" />
      </div>
      <div class="btn_box">
        <div class="btn">
          <van-button type="default"
                      plain
                      size="small"
                      @click="showPersonnelPop = false">{{$t('module.cancel')}}</van-button>
          <van-button type="info"
                      size="small"
                      @click="confirm">{{$t('module.confirm')}}</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 选择产线数据 -->
    <van-popup v-model="showLinePicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :title="'产线选择'"
                  :columns="lineList"
                  :value-key="'linename'"
                  @confirm="onLinePickerConfirm"
                  @cancel="showLinePicker = false">
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getUserLineList, getCommSelectEmpFieldList, getLineEmpList } from '@api/selectPersonnel.js'
export default {
  props: {
    single: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
    return {
      userInfo,
      showPersonnelPop: false,
      popTitle: '人员',
      searchPopVal: '',
      lineObj: {},
      lineName: '',
      lineList: [],
      showLinePicker: false,
      autoThList: [],
      autoTableData: [],
      initautoTableData: [],
      currentPage: 1,
      totalItems: 0,
      pageSize: 10
    }
  },
  watch: {
    showPersonnelPop (n) {
      if (n)
      {
        this.getCommSelectEmpFieldListData()
        this.getUserLineListData()
        this.pagesize = window.pop_tree_pagesize ? window.pop_tree_pagesize : 10
      } else
      {
        this.searchPopVal = ''
        this.lineName = ''
        this.lineObj = {}
        this.lineList = []
        this.showLinePicker = false
        this.autoThList = []
        this.autoTableData = []
        this.initautoTableData = []
        this.currentPage = 1
        this.totalItems = 0
        this.pageSize = 10
      }
    }
  },
  computed: {
    checkedList () {
      return this.initautoTableData.filter(it => it.checked)
    }
  },
  created () {

  },
  methods: {
    // 当清空查询条件时按照产线查询
    changeSearch () {
      if (!this.searchPopVal)
      {
        this.getLineEmpListData(this.lineObj.lineid, '')
      }
    },
    // 选择产线回调
    onLinePickerConfirm (val) {
      this.lineObj = val
      this.lineName = val.linename
      this.getLineEmpListData(val.lineid, '')
      this.showLinePicker = false
    },
    // 确定按钮
    confirm () {
      if (this.checkedList.length == 0)
      {
        Toast.fail(this.$t('module.RemindToChoose'))
      } else
      {
        this.$emit('getPersonnel', this.checkedList)
        this.showPersonnelPop = false
      }
    },
    // 搜索
    onSearchPerson () {
      if (this.searchPopVal)
      {
        this.getLineEmpListData('', this.searchPopVal)
      } else
      {
        Toast.fail(this.$t('module.PleaseEnterKeyWords'))
      }
    },
    // 点击表格行
    changeAuditDataByTd (val) {
      this.autoTableData.forEach(ele => {
        if (this.single)
        {
          // 单选
          if (val.empid == ele.empid)
          {
            ele.checked = !ele.checked
          } else
          {
            ele.checked = false
          }
        } else
        {
          // 多选
          if (val.empid == ele.empid)
          {
            ele.checked = !ele.checked
          }
        }
      });
    },
    // 分页操作
    changePage () {
      this.autoTableData = this.initautoTableData.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      )
    },
    // 选择产线后返回对应的人员名单接口（或搜索接口）
    getLineEmpListData (id, val) {
      getLineEmpList({
        lineid: id,
        queryfilter: val,
        username: this.userInfo.username
      }).then(res => {
        let ary = res.data
        ary.forEach((ele, idx) => {
          ele.sqNum = idx + 1
          ele.checked = false
        });
        this.initautoTableData = ary
        this.totalItems = ary.length
        this.currentPage = 1
        this.$nextTick(() => {
          this.autoTableData = this.initautoTableData.slice(
            (this.currentPage - 1) * this.pageSize,
            this.currentPage * this.pageSize
          )
        })
      })
    },
    // 获取表格字段列信息
    getCommSelectEmpFieldListData () {
      getCommSelectEmpFieldList({ username: this.userInfo.username }).then(res => {
        this.autoThList = res.data.filter(it => { return it.gridshow == 1 || it.gridshow == true })
        this.autoThList.forEach(el => {
          if (el.fieldname == 'isactive')
          {
            el.datatype = 'checkbox'
          } else
          {
            el.datatype = 'str'
          }
        })
        this.autoThList.unshift({
          fieldname: "checked",
          chnname: "",
          gridwith: 10,
          datatype: 'checkbox'
        })
      })
    },
    // 获取产线下拉列表
    getUserLineListData () {
      getUserLineList({ username: this.userInfo.username }).then(res => {
        this.lineList = res.data
        // 第一次选第一个
        this.lineObj = this.lineList[0]
        this.lineName = this.lineList[0].linename
        this.getLineEmpListData(this.lineList[0].lineid, '')
      })
    },
  }

}
</script>

<style lang="less" scoped>
.selectPersonnel_table {
  position: relative;
  // z-index: 99999;
  .selectPersonnel_popup {
    width: 100%;
    height: 100%;
    .fixed_div {
      .tree_title {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 36px;
          font-weight: bold;
        }
      }
      .tree_search {
        height: 80px;
        display: flex;
        padding: 0 10px;
        .line_cell {
          width: 40%;
          font-size: 28px;
          border: 1px solid #dcdfe6;
          // border-radius: 20px;
          margin-right: 20px;
          padding: 10px 20px;
          align-items: center;
          /deep/.van-cell__title {
            width: 1rem;
          }
        }
        .search_box {
          flex: 1;
          display: flex;
          .van-cell {
            border: 1px solid #dcdfe6;
            padding: 10px 20px;
            align-items: center;
          }
          .icon_box {
            border: 1px solid #dcdfe6;
            font-size: 40px;
            display: flex;
            align-items: center;
          }
        }
      }
      .van-divider::before {
        border-width: 20px 0 0;
      }
    }
    .table_box {
      height: calc(100% - 200px - 110px - 100px);
      .table {
        width: 100%;
        border-spacing: 0px;
        tr {
          td:nth-child(1) {
            border-left: 2px solid #dcdfe6;
          }
          th,
          td {
            padding: 6px 10px;
            text-align: center;
            border-bottom: 2px solid #dcdfe6;
            border-right: 2px solid #dcdfe6;
            .comm_box {
              display: flex;
              justify-content: center;
              .boolean_box {
                .van-checkbox {
                  justify-content: center;

                  /deep/.van-checkbox__icon--checked {
                    .van-icon {
                      color: #fff !important;
                      background-color: #1989fa !important;
                      border-color: #1989fa !important;
                    }
                  }

                  /deep/.van-checkbox__icon--disabled .van-icon {
                    background-color: #fff;
                  }
                }
              }
              .input_box {
                width: 150px;
                .van-cell {
                  font-size: 24px;
                  border: 2px solid #d2d2d2;
                  border-radius: 10px;
                  padding: 8px 16px;
                }
              }
            }
          }
        }
        .top {
          position: sticky;
          top: 0;
          font-size: 28px;
          background: #e9f3fd;
          height: 64px;
          th:nth-child(1) {
            border-left: 2px solid #dcdfe6;
          }
          th {
            border-top: 2px solid #dcdfe6;
          }
        }
        .cont {
          height: 64px;
          font-size: 24px;
          .upload_box {
            display: flex;
            align-items: center;
            justify-content: center;
            /deep/.van-uploader {
              .van-uploader__wrapper {
                .van-uploader__preview {
                  margin: 0;
                }
              }
            }
          }
        }
        .cont:nth-child(2n-1) {
          background: #f5f5f5;
        }
        .nodata {
          td {
            font-size: 28px;
          }
        }
      }
    }
    .paging_box {
      height: 100px;
      display: flex;
      align-items: flex-end;
      .van-pagination {
        width: 100%;
      }
    }
    .btn_box {
      height: 110px;
      display: flex;
      align-items: center;
      padding: 20px;
      background: #ffffff;
      .btn {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex: 1;
        .van-button {
          width: 40%;
          font-size: 32px;
          padding: 10px 0;
          border-radius: 20px;
        }
      }
    }
  }
}
</style>