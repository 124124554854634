import request from '@/utils/request2'

// 获取派工明细接口
export function getPgPlanMaster (data) {
  return request({
    url: '/api/wxjj/getPgPlanMaster',
    method: 'get',
    params: data
  })
}

// 产线下拉列表接口
export function getPgCxList (data) {
  return request({
    url: '/api/wxjj/getPgCxList',
    method: 'get',
    params: data
  })
}

// 机台下拉列表
export function getJtList (data) {
  return request({
    url: '/api/wxjj/getJtList',
    method: 'get',
    params: data
  })
}

// 工序下拉列表
export function getProcessList (data) {
  return request({
    url: '/api/wxjj/getProcessList',
    method: 'get',
    params: data
  })
}

// 批量安排

export function batchPostPg (data) {
  return request({
    url: '/api/wxjj/batchPostPg',
    method: 'post',
    data: data
  })
}

// 产线确定拆分接口
export function postSplitPlan (data) {
  return request({
    url: '/api/wxjj/postSplitPlan',
    method: 'post',
    data: data
  })
}

// 获取派工明细数据接口
export function getEmpPgDataList (data) {
  return request({
    url: '/api/wxjj/getEmpPgDataList',
    method: 'get',
    params: data
  })
}

// 获取协助人员信息
export function getPgXzEmpList (data) {
  return request({
    url: '/api/wxjj/getPgXzEmpList',
    method: 'get',
    params: data
  })
}

// 保存派工明细协助人员明细接口
export function savePgXzEmpList (data) {
  return request({
    url: '/api/wxjj/savePgXzEmpList',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 批量派工
export function postPgDetailData (data) {
  return request({
    url: '/api/wxjj/postPgDetailData',
    method: 'post',
    data: data
  })
}

// 删除派工明细
export function batchDeletePgDetailData (data) {
  return request({
    url: '/api/wxjj/batchDeletePgDetailData',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 派工明细拆分接口
export function calSplitPg (data) {
  return request({
    url: '/api/wxjj/calSplitPg',
    method: 'post',
    data: data
  })
}

// 获取集体派工数据
export function getGroupPgInfo (data) {
  return request({
    url: '/api/wxjj/getGroupPgInfo',
    method: 'get',
    params: data
  })
}

export function getJobList (data) {
  return request({
    url: '/api/wxjj/getJobList',
    method: 'get',
    params: data
  })
}

export function addGroupPg (data) {
  return request({
    url: '/api/wxjj/addGroupPg',
    method: 'get',
    params: data
  })
}

export function deleteGroupPg (data) {
  return request({
    url: '/api/wxjj/deleteGroupPg',
    method: 'get',
    params: data
  })
}

// .保存集体派工明细单笔记录的工号接口
export function savePgDetailEmpInfo (data) {
  return request({
    url: '/api/wxjj/savePgDetailEmpInfo',
    method: 'get',
    params: data
  })
}

// 重新获取集体派工明细接口
export function reGetPgDetail (data) {
  return request({
    url: '/api/wxjj/reGetPgDetail',
    method: 'get',
    params: data
  })
}
