import request from '@/utils/request2'

// 获取产线下拉列表接口
export function getUserLineList (data) {
  return request({
    url: '/api/wxjj/getUserLineList',
    method: 'get',
    params: data
  })
}

// 获取表格字段列信息接口
export function getCommSelectEmpFieldList (data) {
  return request({
    url: '/api/wxjj/getCommSelectEmpFieldList',
    method: 'get',
    params: data
  })
}

// 选择产线后返回对应的人员名单接口（或搜索接口）
export function getLineEmpList (data) {
  return request({
    url: '/api/wxjj/getLineEmpList',
    method: 'get',
    params: data
  })
}