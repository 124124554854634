<template>
  <div class="dispatchingWorkers">
    <van-nav-bar :left-text="pageTitle"
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
      <template #right>
        <div class="showPopup"
             @click="handshowDtPicker">
          <svg-icon icon-class="interview_rili"></svg-icon>
          {{ bd }}
          <span style="margin:0 5px;font-size:12px;color: #333">至</span>
          {{ ed }}
          <span>
            <svg-icon icon-class="dropdown"></svg-icon>
          </span>
        </div>
      </template>
    </van-nav-bar>
    <div class="contentTop"
         style="top: 1.22667rem">
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <van-search v-model="searchStr"
                    shape="round"
                    placeholder="单号/订单号/生产类别/工作模式/工件号/工件名/机台"
                    @search="onSearch" />
        <van-button @click="plApEvent"
                    v-if="planMasterData.length > 0 && readyDispatchingWorkers == 2"
                    style="padding: 0 0.6rem"
                    round
                    type="info"
                    icon="apps-o"
                    size="small">批量安排</van-button>
      </div>
      <div class="danduSearch"
           style="display: flex;align-items: center;justify-content: space-between;">
        <div class="danbu_box">
          <van-field v-model="searchWorkmode"
                     label="生产模式"
                     readonly
                     right-icon="arrow-down"
                     placeholder="生产模式"
                     @click="showWorkmodePicker = true" />
          <van-popup v-model="showWorkmodePicker"
                     position="bottom"
                     round>
            <van-picker show-toolbar
                        :columns="['无', '白班', '晚班']"
                        @confirm="onWorkmodePickerConfirm"
                        @cancel="showWorkmodePicker = false">
            </van-picker>
          </van-popup>
        </div>
        <div class="danbu_box">
          <van-field v-model="searchIsPG"
                     label="派工"
                     readonly
                     right-icon="arrow-down"
                     placeholder="派工"
                     @click="showsearchIsPGPicker = true" />
          <van-popup v-model="showsearchIsPGPicker"
                     position="bottom"
                     round>
            <van-picker show-toolbar
                        :columns="['已派', '未派', '所有']"
                        @confirm="onsearchIsPGPickerConfirm"
                        @cancel="showsearchIsPGPicker = false">
            </van-picker>
          </van-popup>
        </div>
      </div>
    </div>
    <div class="fixedH"
         style="height: 158px"></div>
    <div class="content">
      <div class="contentMain"
           v-if="planMasterData.length > 0">
        <div class="contentMainContainer"
             v-for="(value, key) in planMasterData"
             :key="key">
          <div class="tags"
               v-if="value.isbg || value.ispc">
            <van-tag v-if="value.isbg"
                     mark
                     type="danger"
                     :style="{ marginRight: value.ispc ? '5px' : '0' }">已派</van-tag>
            <van-tag v-if="value.ispc"
                     mark
                     type="danger">已排</van-tag>
          </div>
          <div class="contentMainContainerTop">
            <div class="pgDate">
              <div class="title">单号</div>
              {{ value.listno }}
            </div>
            <div class="pgDate">
              <div class="title">订单</div>
              {{ value.orderid }}
            </div>
            <div class="contentMainContainerTopNode">
              <div class="contentMainContainerTopNodeTit">日期</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.yymmdd"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode">
              <div class="contentMainContainerTopNodeTit">产线</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select"
                     @click="selectDataEvent(value, key)">
                  <input v-model="value.linename"
                         class="h5SelectInput"
                         :class="!value.canedit ? 'readonlydata' : ''"
                         readonly
                         type="text" />
                  <van-icon class="h5SelectIcon"
                            name="arrow-down" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="width:100%">
              <div class="contentMainContainerTopNodeTit">
                工件
                <span style="color: #333;">（{{ value.partcode }}）</span>
              </div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.partname"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">机台</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.machinename"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">数量</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.plannum"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">合格数量</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.bgnum"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">剩余数量</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.wscnum"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">生产类别</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.sctype"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">工作模式</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.workmode"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">客户</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.cus_kh"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 style="padding-left: 0.10667rem; padding-right: 0">
              <div class="contentMainContainerTopNodeTit">计划总数量</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.plannum_zb"
                         class="h5SelectInput readonlydata"
                         readonly
                         type="text" />
                </div>
              </div>
            </div>
            <div class="contentMainContainerTopNode"
                 v-if="value.show_sytimes"
                 style="width:100%">
              <div class="contentMainContainerTopNodeTit">丝印次数</div>
              <div class="contentMainContainerTopNodeInput">
                <div class="h5Select h5Input">
                  <input v-model="value.sytimes"
                         class="h5SelectInput"
                         :class="value.edit_sytimes ? '' : 'readonlydata'"
                         :readonly="!value.edit_sytimes"
                         type="text" />
                </div>
              </div>
            </div>
          </div>
          <div class="contentMainContainerFooter">
            <div class="contentMainContainerFooterLeft">
              <van-checkbox v-model="value.checked"
                            :disabled="readyDispatchingWorkers == 1"></van-checkbox>
            </div>
            <div class="contentMainContainerFooterRight">
              <div class="rightBtn"
                   @click="toDetail(value, key)">
                <div class="rightBtnImg">
                  <img src="@/assets/img/paigongmingxi.png"
                       alt="" />
                </div>
                <div class="rightBtnWord">派工明细</div>
              </div>
              <div class="rightBtn"
                   v-if="readyDispatchingWorkers == 2 && value.cansplit">
                <div class="rightBtnImg">
                  <img src="@/assets/img/cafenchanxian.png"
                       alt="" />
                </div>
                <div class="rightBtnWord"
                     @click="cfcxEvent(value, key)">
                  拆分产线
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else
           style="
          height: calc(100vh - 3rem);
          display: flex;
          align-items: center;
          justify-content: center;
        ">
        <van-empty description="暂无数据" />
      </div>
    </div>
    <!-- 选择产线 -->
    <van-popup v-model="showChanXianPicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :columns="listData"
                  @confirm="onChanXianPickerConfirm"
                  @cancel="showChanXianPicker = false">
      </van-picker>
    </van-popup>
    <!-- 选择日期 -->
    <van-calendar v-model="showDtPicker"
                  type="range"
                  allow-same-day
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onDtConfirm" />
    <!-- 拆分产线 -->
    <van-dialog v-model="showSplitDispatchOrder"
                class="SplitDispatchOrder_report"
                show-cancel-button
                confirm-button-text="确定"
                confirm-button-color="#0782F5"
                :beforeClose="submitSplitDispatchOrder">
      <template #title>
        <div class="title">拆分产线</div>
        <div class="des">请谨慎操作</div>
      </template>
      <div class="content">
        <div class="contentPersonnel">
          <div class="contentPersonnelTop">
            <div class="contentPersonnelTopLeft">
              <img class="contentPersonnelTopLeftIcon"
                   src="@/assets/img/xiezhurenyuan.png"
                   alt="" />
              <span class="contentPersonnelTopLeftSpan">
                <span style="margin-right: 5px;">带班</span>
                <strong v-if="daiBanRy">{{ daiBanRy }}</strong>
              </span>
            </div>
            <div class="contentPersonnelTopRight"
                 @click="showEmpTreePop">
              <img class="contentPersonnelTopRightIcon"
                   src="@/assets/img/qdwqdqqwdwqd.png"
                   alt="" />
              <span>选择</span>
            </div>
          </div>
          <div class="xxxxxxxxxxxx"></div>
        </div>
        <div class="contentCell"
             v-if="cfSelectData">
          <div class="AssistCell">
            <div class="AssistCellLeft">日期</div>
            <div class="AssistCellRight">
              <div class="h5Select"
                   @click="showOneDPicker = true">
                <input class="h5SelectInput"
                       :class="hasChangeDb ? 'readonlydata' : ''"
                       v-model="cfSelectData.yymmdd"
                       readonly
                       type="text" />
                <van-icon class="h5SelectIcon"
                          name="arrow-down" />
              </div>
            </div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">产线</div>
            <div class="AssistCellRight">
              <div class="h5Select"
                   @click="selectDbCxEvent">
                <input class="h5SelectInput"
                       :class="hasChangeDb ? 'readonlydata' : ''"
                       v-model="cfSelectData.linename"
                       readonly
                       type="text" />
                <van-icon class="h5SelectIcon"
                          name="arrow-down" />
              </div>
            </div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">机台</div>
            <div class="AssistCellRight">{{ cfSelectData.machinename }}</div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">工件</div>
            <div class="AssistCellRight">{{ cfSelectData.partname }}</div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">数量</div>
            <div class="AssistCellRight h5Input"
                 style="
                border: 1px solid #ccc;
                width: 100%;
                margin-left: 0.5rem;
                padding-left: 0rem;
              ">
              <input class="h5SelectInput"
                     v-model="cfSelectData.plannum"
                     type="text" />
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- 选择产线人员弹窗 -->
    <PersonnelPop ref="refPersonnelPop"
                  :single="true"
                  @getPersonnel="getPersonnel"></PersonnelPop>
    <!-- 提示信息弹窗 -->
    <van-dialog v-model="showHintDia"
                class="hint_dialog"
                :show-confirm-button="false">
      <template #title>
        <div style="height:60px"></div>
      </template>
      <div class="content">
        <img :src="require(isSuccess
      ? '@/assets/img/job_booking11.png'
      : '@/assets/img/job_booking10.png')
      "
             alt />
        <div class="hint_msg">{{ hintMessage }}</div>
        <van-button type="info"
                    size="small"
                    round
                    @click="showHintDia = false">好的</van-button>
      </div>
    </van-dialog>

    <!-- 选择单个日期 -->
    <van-calendar v-model="showOneDPicker"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onOneDConfirm" />
  </div>
</template>
<script>
import PersonnelPop from "@components/selectPersonnel/index.vue";
import {
  getPgPlanMaster,
  getPgCxList,
  batchPostPg,
  postSplitPlan
} from '@api/paigong.js'
import { parseTime } from '@/utils'
import { Toast } from 'vant'
export default {
  name: 'dispatchingWorkers',
  components: {
    PersonnelPop
  },
  data () {
    let sData1 = this.$route.query.bd || new Date()
    let sData2 = this.$route.query.ed || new Date()
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      sData1,
      sData2,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      bd: parseTime(sData1, '{y}-{m}-{d}'),
      ed: parseTime(sData2, '{y}-{m}-{d}'),
      checked: true,
      listData: [],
      bslistData: [],
      planMasterData: [],
      initPlanMasterData: [],
      selectItem: null,
      selectIndex: 0,
      showChanXianPicker: false,
      showDtPicker: false,
      showSplitDispatchOrder: false,
      daiBanRy: '', // 带班人员
      daiBanRyId: null,
      initdaiBanRyId: null,
      daiBanRyCode: null,
      dbType: 1, // 1是通过拆分产线弹出框选择产线，2是通过列表选择产线
      cfSelectData: null, //
      successpath: require('@/assets/img/chenggong.png'),
      readyDispatchingWorkers: 2,
      showHintDia: false,
      isSuccess: true,
      hintMessage: '',
      searchStr: '', // 搜索
      showOneDPicker: false,
      scroll: 0, // 记录滚动的距离
      searchWorkmode: '',
      showWorkmodePicker: false,
      searchIsPG: '',
      showsearchIsPGPicker: false,
      searchisbg: null
    }
  },
  computed: {
    // 拆分弹窗中是否选择过带班
    hasChangeDb () {
      return this.initdaiBanRyId != this.daiBanRyId
    },
    pageTitle () {
      let title = '排产计划'
      if (this.$route.query)
      {
        title = this.$route.query.title
      }
      return title
    }
  },
  beforeRouteLeave (to, from, next) {
    // 获取滚动距离
    this.scroll = document.getElementsByClassName(
      'dispatchingWorkers'
    )[0].scrollTop
    next()
  },
  activated () {
    // 当从详情返回时回到上次滚动距离
    this.$nextTick(() => {
      setTimeout(() => {
        document.getElementsByClassName(
          'dispatchingWorkers'
        )[0].scrollTop = this.scroll
      }, 0)
    })
  },
  watch: {
    $route (to, from) {
      if (
        from.name == 'dispatchingWorkersDetail' ||
        from.name == 'dispatchingWorkersDetailJt'
      )
      {
        this.getData()
      }
    }
  },
  created () {
    this.readyDispatchingWorkers = sessionStorage.getItem(
      'readyDispatchingWorkers'
    )
    this.getPgCxList()
    this.getData()
  },
  methods: {
    // 条件派工选择回调
    onsearchIsPGPickerConfirm (val) {
      this.searchIsPG = val
      if (val == '已派')
      {
        this.searchisbg = 'true'
      } else if (val == '未派')
      {
        this.searchisbg = 'false'
      } else if (val == '所有')
      {
        this.searchisbg = null
      }
      this.onSearch()
      this.showsearchIsPGPicker = false
    },
    // 条件工作模式选择回调
    onWorkmodePickerConfirm (val) {
      if (val == '无')
      {
        this.searchWorkmode = ''
      } else
      {
        this.searchWorkmode = val
      }
      this.onSearch()
      this.showWorkmodePicker = false
    },
    // 选择单个日期回调
    onOneDConfirm (date) {
      this.cfSelectData.yymmdd = parseTime(date, '{y}-{m}-{d}')
      this.showOneDPicker = false
    },
    // 搜索方法
    onSearch (noIni) {
      if (this.searchStr || this.searchWorkmode || this.searchisbg)
      {
        this.planMasterData = this.initPlanMasterData.filter(e => {
          const listno = e.listno ? e.listno : ''
          const orderid = e.orderid ? e.orderid : ''
          const sctype = e.sctype ? e.sctype : ''
          const workmode = e.workmode ? e.workmode : ''
          const partcode = e.partcode ? e.partcode : ''
          const partname = e.partname ? e.partname : ""
          const machinename = e.machinename ? e.machinename : ''
          const isbg = e.isbg ? 'true' : 'false'
          if (this.searchWorkmode && this.searchisbg)
          {
            // console.log(1);

            return (
              (listno.indexOf(this.searchStr) > -1 ||
                orderid.indexOf(this.searchStr) > -1 ||
                workmode.indexOf(this.searchStr) > -1 ||
                partcode.indexOf(this.searchStr) > -1 ||
                partname.indexOf(this.searchStr) > -1 ||
                machinename.indexOf(this.searchStr) > -1) &&
              sctype.indexOf(this.searchWorkmode) > -1 &&
              isbg == this.searchisbg
            )
          } else if (!this.searchWorkmode && this.searchisbg)
          {
            //  console.log(2);
            return (
              (listno.indexOf(this.searchStr) > -1 ||
                orderid.indexOf(this.searchStr) > -1 ||
                workmode.indexOf(this.searchStr) > -1 ||
                partcode.indexOf(this.searchStr) > -1 ||
                partname.indexOf(this.searchStr) > -1 ||
                machinename.indexOf(this.searchStr) > -1) &&
              isbg == this.searchisbg
            )
          } else if (this.searchWorkmode && !this.searchisbg)
          {
            // console.log(3,this.searchWorkmode,this.searchStr);
            return (
              (listno.indexOf(this.searchStr) > -1 ||
                orderid.indexOf(this.searchStr) > -1 ||
                workmode.indexOf(this.searchStr) > -1 ||
                partcode.indexOf(this.searchStr) > -1 ||
                partname.indexOf(this.searchStr) > -1 ||
                machinename.indexOf(this.searchStr) > -1) &&
              sctype.indexOf(this.searchWorkmode) > -1
            )
          } else
          {
            return (
              listno.indexOf(this.searchStr) > -1 ||
              orderid.indexOf(this.searchStr) > -1 ||
              workmode.indexOf(this.searchStr) > -1 ||
              partcode.indexOf(this.searchStr) > -1 ||
              partname.indexOf(this.searchStr) > -1 ||
              machinename.indexOf(this.searchStr) > -1
            )
          }
        })
      } else if (noIni)
      {
        // 是否需要重新获取原始数据
        this.planMasterData = JSON.parse(
          JSON.stringify(this.initPlanMasterData)
        )
      } else
      {
        this.getData()
      }
    },
    // 选择带班人员回调
    getPersonnel (data) {
      let item = data[0]
      this.daiBanRy = item.empname
      this.daiBanRyId = item.empid
      this.daiBanRyCode = item.empcode
      this.cfSelectData.empid_db = item.empid
      if (this.hasChangeDb)
      {
        // 如果选择别的带班就清空产线，只读
        this.cfSelectData.lineid = ''
        this.cfSelectData.linename = ''
      } else
      {
      }
    },
    showEmpTreePop () {
      this.$refs.refPersonnelPop.showPersonnelPop = true
    },
    // 拆分弹窗产线下拉
    selectDbCxEvent () {
      if (!this.hasChangeDb)
      {
        this.dbType = 1
        this.showChanXianPicker = true
      }
    },
    onClickLeft () {
      this.$router.push('/home')
    },
    // 下拉框选择日期
    onDtConfirm (v) {
      this.bd = parseTime(v[0], '{y}-{m}-{d}')
      this.ed = parseTime(v[1], '{y}-{m}-{d}')
      this.getData()
      this.showDtPicker = false
    },
    // 派工明细
    toDetail (value, key) {
      if (!value.linename || !value.lineid)
      {
        Toast('请设置产线')
        return
      } else
      {
        batchPostPg({
          username: this.userInfo.username,
          data: [
            {
              autoid: value.autoid,
              lineid: value.lineid,
              sytimes: value.sytimes
            }
          ]
        }).then(res => {
          if (value.jxtype == '集体计产')
          {
            this.$router.push({
              path: '/dispatchingWorkersDetailJt',
              query: {
                autoid: value.autoid,
                bd: this.bd,
                ed: this.ed,
                yymmdd: value.yymmdd,
                linename: value.linename,
                machinename: value.machinename,
                partname: value.partname,
                plannum: value.plannum,
                listno: value.listno
              }
            })
          } else
          {
            this.$router.push({
              path: '/dispatchingWorkersDetail',
              query: {
                autoid: value.autoid,
                bd: this.bd,
                ed: this.ed,
                yymmdd: value.yymmdd,
                linename: value.linename,
                machinename: value.machinename,
                partname: value.partname,
                plannum: value.plannum,
                listno: value.listno
              }
            })
          }
        })
      }

      return
    },
    // 产线下拉回调
    selectDataEvent (value, key) {
      if (this.readyDispatchingWorkers == 2 && value.canedit)
      {
        this.dbType = 2
        this.selectItem = value
        this.selectIndex = key
        this.showChanXianPicker = true
      }
    },
    // 显示下拉框
    handshowDtPicker () {
      this.showDtPicker = true
    },
    onChanXianPickerConfirm (value) {
      let arr = this.bslistData.filter(item => {
        return item.linename == value
      })
      if (this.dbType == 1)
      {
        this.cfSelectData.lineid = arr[0].lineid
        this.cfSelectData.linename = value
      } else
      {
        this.planMasterData[this.selectIndex].linename = value
        this.planMasterData[this.selectIndex].checked = true
        this.planMasterData[this.selectIndex].lineid = arr[0].lineid
        this.planMasterData[this.selectIndex].linecode = arr[0].linecode
      }
      this.showChanXianPicker = false
    },
    getPgCxList () {
      getPgCxList({
        username: this.userInfo.username
      }).then(res => {
        this.bslistData = res.data
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.listData.push(res.data[i].linename)
          }
        }
      })
    },
    // 获取派工列表
    getData () {
      getPgPlanMaster({
        username: this.userInfo.username,
        bd: this.bd,
        ed: this.ed
      }).then(res => {
        let result = JSON.parse(JSON.stringify(res.data))
        // res.data.forEach((item) => {
        //   if (item.lineid)          {
        //     item.checked = true;
        //   } else          {
        //     item.checked = false;
        //   }
        // });
        // this.planMasterData = result
        this.initPlanMasterData = result
        // 2023.10.01 lbj 日期更改后仍需要根据查询条件显示数据
        this.onSearch(true)
        // console.log(this.planMasterData,'getPgPlanMaster')
      })
    },
    plApEvent () {
      let arr = []
      for (let i = 0; i < this.planMasterData.length; i++)
      {
        if (this.planMasterData[i].checked && this.planMasterData[i].lineid)
        {
          let obj = {
            autoid: this.planMasterData[i].autoid,
            lineid: this.planMasterData[i].lineid,
            sytimes: this.planMasterData[i].sytimes
          }
          arr.push(obj)
        }
      }
      if (arr.length > 0)
      {
        batchPostPg({
          username: this.userInfo.username,
          data: arr
        }).then(res => {
          Toast('安排成功')
          this.getData()
        })
      } else
      {
        Toast('请选择需要安排的数据')
      }
      console.log(this.planMasterData, 'this.planMasterDatathis.planMasterData')
    },
    // 拆分产线弹窗回调
    submitSplitDispatchOrder (action, done) {
      if (action == 'confirm')
      {
        if (!this.cfSelectData.empid_db)
        {
          Toast('请选择带班人员！')
          done(false)
          return
        }
        if (!this.cfSelectData.lineid && !this.hasChangeDb)
        {
          Toast('请选择产线！')
          done(false)
          return
        }
        postSplitPlan({
          autoid: this.cfSelectData.autoid,
          empid_db: this.cfSelectData.empid_db,
          lineid: this.cfSelectData.lineid,
          machineid: this.cfSelectData.machineid,
          partid: this.cfSelectData.partid,
          plannum: this.cfSelectData.plannum,
          yymmdd: this.cfSelectData.yymmdd,
          username: this.userInfo.username
        }).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')
          {
            this.isSuccess = true
            this.hintMessage = '拆分产线成功！'
            this.getData()
            done()
          } else
          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
            done(false)
          }
        })
      } else
      {
        done()
      }
    },
    // 点击拆分产线
    cfcxEvent (value) {
      if (!value.linename || !value.lineid)
      {
        Toast('请设置产线')
        return
      } else
      {
        batchPostPg({
          username: this.userInfo.username,
          data: [
            {
              autoid: value.autoid,
              lineid: value.lineid,
              sytimes: value.sytimes
            }
          ]
        }).then(res => {
          this.initdaiBanRyId = value.empid_db
          this.daiBanRy = value.empname_db
          this.daiBanRyId = value.empid_db
          this.daiBanRyCode = value.empcode_db
          // this.cfSelectData = Object.assign(value, { empid_db: 0 });
          this.cfSelectData = {
            ...value,
            ...{ yymmdd: parseTime(new Date(), '{y}-{m}-{d}') }
          }
          this.showSplitDispatchOrder = true
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.hint_dialog {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_booking09.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .desc {
      font-size: 28px;
    }
  }

  .content {
    text-align: center;

    img {
      width: 160px;
      height: 160px;
    }

    .hint_msg {
      margin-top: 20px;
      font-size: 32px;
      font-weight: bold;
    }

    .van-button {
      margin: 40px 0;
      width: 240px;
      font-size: 28px;
    }
  }
}

.dispatchingWorkers {
  height: 100vh;
  background-image: url("~@/assets/img/dispatchingWorkersHeader.png");
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;
  overflow: auto;

  .van-nav-bar {
    background: #bcdfff;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }

      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }

    .showPopup {
      color: #333;
      font-size: 24px;

      span {
        color: #999;
        font-size: 24px;
        margin-left: 10px;

        .svg-icon {
          margin: 0;
        }
      }

      .svg-icon {
        // margin: 0 5px;
      }
    }
  }

  .contentTop {
    width: 100%;
    margin: 28px 0;
    padding: 0 26px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    position: fixed;
    z-index: 999;

    .van-search {
      background-color: unset;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      max-width: 60%;
    }

    .danduSearch {
      margin-top: 20px;

      .danbu_box {
        width: 48%;

        .van-cell {
          border-radius: 26.64rem;
          padding: 10px 10px;
          font-size: 28px;

          /deep/.van-cell__title {
            width: auto;
          }
        }
      }
    }
  }

  .content {
    .contentMain {
      width: 100%;
      padding: 0 26px;

      .contentMainContainer {
        width: 100%;
        background: #fff;
        border-radius: 12px;
        box-shadow: 4px 3px 15px 0px rgba(175, 175, 175, 0.21);
        margin-bottom: 23px;

        .tags {
          font-size: 24px;
          text-align: right;
          padding: 10px;
        }
      }

      .contentMainContainerTop {
        padding: 13px 46px 36px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .pgDate {
          font-size: 26px;
          width: 100%;
          display: flex;
          align-items: center;

          .title {
            margin-right: 20px;
            font-size: 28px;
            color: #8d8d8d;
          }
        }
      }

      .contentMainContainerTopNode {
        width: 50%;
        padding-right: 8px;
        margin-top: 23px;
      }

      .contentMainContainerTopNodeTit {
        width: 100%;
        font-size: 28px;
        color: #8d8d8d;
        margin-bottom: 13px;
      }

      .contentMainContainerTopNodeInput {
        width: 100%;
      }

      .contentMainContainerFooter {
        width: 100%;
        border-top: 2px solid #dcdcdc;
        height: 92px;
        display: flex;
        align-items: center;
        padding: 0 46px;
        justify-content: space-between;
      }

      .contentMainContainerFooterRight {
        display: flex;
        align-items: center;
      }

      .rightBtn {
        display: flex;
        align-items: center;
        margin-left: 48px;
      }

      .rightBtnImg {
        width: 28px;

        img {
          display: block;
          width: 28px;
        }
      }

      .rightBtnWord {
        font-size: 30px;
        line-height: 1;
        margin-left: 9px;
      }
    }
  }
}

.readonlydata {
  background: #f9f8f8;
}

.SplitDispatchOrder_report {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/weffwfeffefe.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    height: 2.3rem;

    .title {
      font-size: 52px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 20px 40px;
      text-align: left;
      padding-bottom: 0;
      padding-top: 10px;
    }

    .des {
      font-size: 28px;
      text-align: left;
      padding-left: 40px;
    }
  }
}

.contentCell {
  width: 100%;
  padding: 0 0.53333rem;

  padding-top: 35px;

  .AssistCell {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    align-items: center;

    .AssistCellLeft {
      width: 1rem;
      min-width: 1rem;
      line-height: 1;
      text-align: right;
      color: #7f7f7f;
    }

    .AssistCellRight {
      flex: 1;
      line-height: 1;
      padding-left: 0.56rem;
      width: 100%;
      max-width: calc(100% - 1rem);
    }
  }
}

.contentPersonnel {
  padding: 0 0.53333rem;

  .xxxxxxxxxxxx {
    width: 100%;
    border-top: 1px solid #e5e5e5;
    margin-top: 32px;
  }
}

.contentPersonnelTop {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .contentPersonnelTopLeft {
    display: flex;
    align-items: center;

    .contentPersonnelTopLeftIcon {
      width: 34px;
      height: 35px;
    }

    .contentPersonnelTopLeftSpan {
      font-size: 32px;
      line-height: 1;
      margin-left: 10px;
    }
  }

  .contentPersonnelTopRight {
    display: flex;
    align-items: center;

    .contentPersonnelTopRightIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    span {
      font-size: 32px;
      line-height: 1;
    }
  }
}

.contentPersonnelMain {
  margin-top: 30px;
}

.contentPersonnelMainCell {
  display: flex;
  align-items: center;

  .contentPersonnelMainIcon {
    width: 32px;
    height: 34px;
  }

  span {
    line-height: 1;
    font-size: 32px;
    margin: 0 14px;
  }
}

.h5Select {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d2d2d2;

  .h5SelectInput {
    border: 0;
    flex: 1;
    max-width: calc(100% - 48px);
    font-size: 32px;
    padding: 16px;
  }

  .h5SelectIcon {
    width: 48px;
    font-size: 32px;
  }
}

.h5Input {
  .h5SelectInput {
    border: 0;
    flex: 1;
    max-width: 100%;
    font-size: 32px;
    padding: 16px;
  }
}
</style>
